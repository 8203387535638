/*    ************Input styling - with thanks to CSS tricks (again!) **************  */

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    cursor: pointer;
    margin-top: -5px;
  }
  
  input[type="range"]::-moz-range-thumb {
    height: 36px;
    width: 16px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb {
    height: 36px;
    width: 16px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    height: 5.4px;
    background: #cccccc;
  }
  
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #cccccc;
  }
  
  input[type="range"]::-moz-range-track {
    height: 5.4px;
    background: #cccccc;
  }
  
  input[type="range"]::-ms-track {
    height: 5.4px;
    background: #cccccc;
  }
  input[type="range"]::-ms-fill-lower {
    background: #cccccc;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #cccccc;
  }
  input[type="range"]::-ms-fill-upper {
    background: #cccccc;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #367ebd;
  }
  