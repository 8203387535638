
.App {
  text-align: center;
  margin: 0px;
}

.linearGrad {
  background-image: linear-gradient(rgba(10,30,33,1), rgba(10,30,33,0) 100%);
  color: rgb(255,10,230);

}

.main{
width: 100%;

  text-align: center;
}

body {
  margin: 0;
  background-color: rgb(50,30,83);

}


#navBar {
  border-color: none;
  background-color: rgb(10,30,33);
  width: 100%;
  height: 50px;
  text-align: center;
}

#navBar ul{
  margin: 0px;
  padding: 10px;
}


#navBar li{
  display: inline;
  list-style-type: none;
  margin-right: 10px;
  margin-left: 10px;
}

#navBar a{
  color: rgb(255,10,230);
  text-decoration: none;
}

#navBar a:hover{

  color: rgb(255,255,255);
  
}

#businessCard {
  position: absolute;
  top: 50%; right: 50%;
  transform: translate(50%,-50%);
  background-color: rgb(20,0,30);
}



